<template>
    <div>Esporta Stati Affiliazione</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Stati Affiliazioni", route: "/settings/affiliazioni-stati/index" },
             { title: "Esporta Stati Affiliazione" }
         ]);
     }
 };
</script>
